<template>
  <div>
    <div>
      <el-dropdown @command="handleClick" style="margin-bottom: 10px;">
        <strong class="el-dropdown-link">
          选科模式： {{ type }} <i class="el-icon-arrow-down el-icon--right"></i>
        </strong>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">通过意向院校/专业确定选科组合</el-dropdown-item>
          <el-dropdown-item command="2">通过选科组合查看意向院校/专业</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: '',
  data() {
    return {
      type: '通过意向院校/专业确定选科组合',
    };
  },


  created() {
    this.$emit("Nav", 2)
  },

  methods: {
    handleClick(e) {
      if (e == 1) {
        this.type = '通过意向院校/专业确定选科组合'
        this.$router.push('/newChooseSubject/chooseSpecialty')
      } else {
        this.type = '通过选科组合查看意向院校/专业'
        this.$router.push('/newChooseSubject/chooseSpecialty/analyse')
      }
    }
  },
};
</script>

<style scoped lang='less'></style>
